import React, { memo } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Heading } from "grommet";
import FeaturedItem from "./FeaturedItem";
import NavLink from "../Link/NavLink";
import { transformEdgesNodes } from "../utils";
import { breakpoint } from "../../theme";

export const FeaturedContentFragment = graphql`
	fragment FeaturedContent on MarkdownRemarkEdge {
		node {
			fields {
				slug
			}
			frontmatter {
				title
				description
				featuredpost
				date(formatString: "MMMM DD, YYYY")
        showNew
			}
			...RemoteBannerImageSmall
  		}
	}`;

const ItemsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: ${({ containerJustify }) => containerJustify || "space-between"};
	width: 100%;
	//min-height: 300px;

	${breakpoint("computer", true)`
		justify-content: center;
	`}

	${breakpoint("tablet")`
		justify-content: center;
	`}
`;

const MoreLink = styled(NavLink)`
	text-align: left;
	display: inline-block;
`;

const TitleLink = styled(NavLink)`
	justify-content: center;
`;

const TitleHeading = styled(Heading)`
	text-align: center;
`;

const Container = styled.div`
	padding: 10px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
`;

// type Props = {
// 	moreLink?: String,
// 	title: String,
// 	items: Object[],
// 	className?: String,
// 	ItemComponent?: Function,
// 	containerJustify?: string,
// 	onItemClick?: Function,
// 	hideItemDescription?: boolean,
// };

const renderTitle = (props) =>
	props.moreLink ?
		<TitleLink to={props.moreLink}>
			<Heading level={2}>{props.title}</Heading>
		</TitleLink> :
		<TitleHeading level={2} margin={{ bottom: "medium" }}>{props.title}</TitleHeading>;

const FeaturedContent = (props) => {
	const items = transformEdgesNodes(props.items);

	const FeaturedItemComponent = props.ItemComponent || FeaturedItem;

	return (<Container className={props.className}>
		{renderTitle(props)}
		<br />
		<ItemsContainer containerJustify={props.containerJustify}>
			{items.map((item) =>
				<FeaturedItemComponent
					key={item.slug}
					item={item}
					itemCount={items.length}
					onClick={props.onItemClick}
					hideDescription={props.hideItemDescription}
				/>)}
		</ItemsContainer>
		<br />
		{props.moreLink && <MoreLink to={props.moreLink}>עוד...</MoreLink>}
	</Container>);
};

export default memo(FeaturedContent);
