import React, { useLayoutEffect, useState } from "react";
import { graphql } from "gatsby";
import qs from "qs";
import styled from "styled-components";
import PageBanner from "../components/PageBanner/PageBanner";
import PageSection from "../components/PageSection/PageSection";
import FeaturedEvents, { StyledFeaturedEvent } from "../components/FeaturedEvents/FeaturedEvents";
import { HTMLContent } from "../components/Content";
import EventHighlightSection from "../components/EventHighlightSection/EventHighlightSection";
import withLayoutAndData from "./generic/withLayoutAndData";
import { color } from "../theme";
import FeaturedContent from "../components/FeaturedContent/FeaturedContent";
import { transformEdgesNodes } from "../components/utils";

const MSG_PARSE = /^_([\w\d]*)_(.*)$/;

const ConfirmParagraph = styled.p`
	${color("brand")}
	font-weight:bold;
	font-size: 1.5rem;
`;

const parseMessages = (page) => {
	let msgs;

	if (page.messages) {
		msgs = page.messages.reduce((res, m) => {
			const match = MSG_PARSE.exec(m);
			if (match && match.length) {
				res[match[1]] = match[2];
			}

			return res;
		}, {});
	}

	return msgs;
};

export const ContactSuccessPage = ({ page, highlightedEvents, products }) => {
	const [confirmMessage, setConfirmMsg] = useState(null);
	const messages = parseMessages(page);

	useLayoutEffect(() => {
		if (messages) {
			const query = qs.parse(location.search, { ignoreQueryPrefix: true });
			if (query.id && messages[query.id]) {
				setConfirmMsg(messages[query.id]);
			}
		}
	}, [messages]);

	return (<div>
		<PageBanner title={page.title} image={page.image} />

		<PageSection>
			<HTMLContent content={page.content} />
		</PageSection>

		{confirmMessage && <PageSection>
			<ConfirmParagraph>
				{confirmMessage}
			</ConfirmParagraph>
		</PageSection>}

		{highlightedEvents &&
		<EventHighlightSection bottomBorder>
			<FeaturedEvents
				items={highlightedEvents}
				ItemComponent={StyledFeaturedEvent}
				page={page.slug}
			/>
		</EventHighlightSection>}
		<PageSection>
			<FeaturedContent
				title="מוצרים חדשים באתר"
				items={products}
				containerJustify="center"
				ItemComponent={StyledFeaturedEvent}
				// onItemClick={trackProductClick}
				hideItemDescription
			/>
		</PageSection>
	</div>);
};

export const contactSuccessPageQuery = graphql`
  query ContactSuccessPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
    	id
			html
			fields {
				slug
			}
	    frontmatter {
	      title
			  messages
	    }
	  	...RemoteBannerImage
    }
    products: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: {frontmatter: { allSaleIndex: { eq: true } } }
        limit: 5
    ) {
        edges {
            ...AllSaleItem
        }
    }
  	highlightedEvents: allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] },
			  filter: { frontmatter: { templateKey: { eq: "event-page" }, featuredpost: { eq: true } } },
			  limit: 3
	  ) {
    	edges {
      	...EventHighlight
    	}
  	}
}`;

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			...data.markdownRemark.fields,
			content: data.markdownRemark.html,
		},
		highlightedEvents: data.highlightedEvents,
		products: transformEdgesNodes(data.products),
	})
)(ContactSuccessPage);
