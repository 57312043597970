import styled from "styled-components";
import PageSection from "../PageSection/PageSection";
import { breakpoint, color } from "../../theme";

const EventHighlightSection = styled(PageSection)`
	width: ${({width}) => width || "100%"};
	min-width: 350px;
  ${color("brand-bg-lightest", "bg")}

  ${breakpoint("phone")`
		width: 100%;
	`}
`;

export default EventHighlightSection;
