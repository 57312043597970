import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Box, Heading } from "grommet";
import Image from "../Image/Image";
import { HTMLContent } from "../Content";
import { breakpoint } from "../../theme";
import getIsNewItem from "../../common/getIsNewItem";

const Container = styled(Box)`
	min-width: 240px;
	width: 30%;
	max-width: 300px;
	margin-left: 10px;
	margin-bottom: 20px;

	${breakpoint("computer", true)`
		margin-left: 10px;
	`}

	${breakpoint("phone")`
		width: 100%;
		max-width: none;
		margin-left: 0;
	`}
`;

const FeaturedImage = styled(Image)`
	max-height: 200px;
`;

const Description = styled(HTMLContent)`
	color: ${props => props.theme.global.colors["dark-1"]};
	:hover {
		text-decoration: underline;
	}
`;

const ItemLink = styled(Link)`
	text-decoration: none;

	${breakpoint("phone")`
		text-decoration: none;
	`}
`;

// type Props = {
// 	item: Object,
// 	onClick?: Function,
// };

const FeaturedItem = (props) => {
	const { item, onClick } = props;

	const onItemClick = useCallback(() => {
		if (onClick) {
			onClick(item);
		}
	}, [item, onClick]);

	return (
		<Container pad="small" background={{ color: "light-1", opacity: "strong" }}>
			<ItemLink to={item.slug} onClick={onItemClick}>
				<Heading level={3} color="dark-1" margin={{ bottom: "small" }} textAlign="center"
					responsive={false}>
					{item.title}
				</Heading>

				<FeaturedImage
					image={item.image}
					alt={item.imageAlt || item.title}
					showNew={getIsNewItem(item)}
				/>

				<Description content={item.description} />
			</ItemLink>
		</Container>);
};

export default FeaturedItem;
