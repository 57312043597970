import React, { memo, useCallback } from "react";
import styled from "styled-components";
import EventHighlight from "../EventHighlight/EventHighlight";
import { breakpoint } from "../../theme";
import FeaturedContent from "../FeaturedContent/FeaturedContent";
import { trackEvent } from "../utils";

export const StyledFeaturedEvent = styled(EventHighlight)
	.attrs(({ itemCount }) => ({
		width: ((100 / itemCount) - 3),
	}))`
		margin-left: 10px;

		${breakpoint("phone")`
			margin-left: 0;
		`}
	`;

const FeaturedEvents = ({ page, items, title, ItemComponent = StyledFeaturedEvent }) => {

	const trackFeaturedEventClick = useCallback((item) => {
		trackEvent(`${page} page events`, "FeaturedEvents", `click on event: ${item.title}`, item.slug);
	}, [page]);

	return <FeaturedContent
		title={title}
		items={items}
		containerJustify="center"
		ItemComponent={ItemComponent}
		onItemClick={trackFeaturedEventClick}
	/>;
};

export default memo(FeaturedEvents);
