import React, { useCallback } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Box, Heading, Text } from "grommet";
import { getHebrewDate } from "../utils";
import { HTMLContent } from "../Content";
import Image from "../Image/Image";
import NavLink from "../Link/NavLink";
import { color, breakpoint } from "../../theme";
import getIsNewItem from "../../common/getIsNewItem";

export const EventHighlightFragment = graphql`
	fragment EventHighlight on MarkdownRemarkEdge {
		node {
			fields {
				slug
			}
			frontmatter {
				title
				description
				date
				link
				showDate
			}
			...RemoteBannerImageSmall
  		}
	}`;

const Container = styled(Box)`
	width: ${({ boxWidth }) => boxWidth}%;
	max-width: 700px;
	min-width: 360px;
	min-height: 180px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
	margin-bottom: 20px;
	box-shadow: inset 0 0 6px 7px ${color("brand-bg-light", false)};

	${breakpoint("phone")`
		min-width: 98%;
	`}
`;

const Top = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: 6px;
`;

const Details = styled.div`
	flex-grow: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 15px;

	p {
		font-size: 18px;
		${breakpoint("tablet")`
			font-size: 14px;
		`}
	}
`;

const EventImage = styled(Image)`
	position: absolute;
	width: 100%;
	height: auto;
	max-height: 200px;
`;

const EventHeading = styled(Heading)`

`;

const getEventDate = ({ date }) => {
	const d = new Date(date);
	return `ב - ${getHebrewDate(d)}`;
};

const Bottom = styled.div`
	display: flex;
	flex-direction:column;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const DetailsLink = styled(NavLink)`
	font-weight: 800;
`;

const EventHighlight = (props) => {
	const { item: event, hideDescription, onClick } = props;

	const onEventClick = useCallback(() => {
		if (onClick) {
			onClick(event);
		}
	}, [event, onClick]);

	// console.log("RENDERING EVENT HIGHLIGHT ", event);

	return event ? <Container elevation="medium" pad="small"
		className={props.className} boxWidth={props.width}
		border={{ size: "small", style: "groove" }}>

		<EventImage
			image={event.image}
			showNew={getIsNewItem(event)}
			alt={event.imageAlt || event.title}
		/>

		<Top>
			<Details>
				<NavLink to={event.slug} onClick={onEventClick}>
					<EventHeading level={2} size="medium" margin="none" responsive>
						{event.title}
					</EventHeading>
				</NavLink>
				{!hideDescription ? <Text margin="none" responsive>
					<HTMLContent content={event.description} />
				</Text> : null}
			</Details>
		</Top>

		<Bottom data-date={event.eventDate}>
			{event.showDate ? <Text size="medium" margin={{ bottom: "small" }}>
				{getEventDate(event)}
			</Text> : null}
			<DetailsLink to={event.slug}
				onClick={onEventClick}> לפרטים...</DetailsLink>
		</Bottom>
	</Container> : null;
};

export default EventHighlight;
