const MONTH = (1000 * 60 * 60 * 24 * 31);

const isValid = (d) => !isNaN(d.getTime());

const getIsNewItem = ({ date, showNew }) => {
	let isNew = showNew;

	if (!isNew && date) {
		const d = new Date(date);

		if (isValid(d)) {
			if (Date.now() - d.getTime() < MONTH) {
				isNew = true;
			}
		}
	}

	return isNew;
};

export default getIsNewItem;
